:root {
  --desktop-label-font-size: 24px;
  --desktop-label-font-size-focused: 15px;
  --mobile-label-font-size: 18px;
  --mobile-label-font-size-focused: 13px;
}

.input-field__label {
  color: var(--green-inactive);
  position: absolute;
  font-size: var(--desktop-label-font-size);
  top: 0.8rem;
  left: 0.2rem;
  background-color: var(--modal-background);
  padding: 0 8px;
  transition: 0.3s;
  pointer-events: none
}

.input-field__input {
  color: var(--white);
  width: 100%;
  padding: 13px 10px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--green-inactive);
  transition: all 0.4s ease-in-out;
}

.input-field__input:-webkit-autofill {
  transition: background-color 1s ease-in-out 5000s, color 1s ease-in-out 5000s;
}

.input-field__input:focus {
  border-color: var(--green-main);
}

.input-field__input:focus + .input-field__label {
  top: -0.6rem;
  left: 0.3rem;
  z-index: 1;
  font-size: var(--desktop-label-font-size-focused);
  font-weight: 500;
}

.input-field__input:not(:placeholder-shown).input-field__input:not(:focus) + .input-field__label {
  top: -0.6rem;
  left: 0.3rem;
  z-index: 1;
  font-size: var(--desktop-label-font-size-focused);
  font-weight: 500;
}

.input-field__input.error {
  border-color: var(--red);
}

.input-field__textarea {
  font-family: 'Helvetica Neue', sans-serif;
  color: var(--white);
  width: 100%;
  min-height: 100px;
  max-height: 220px;
  height: auto;
  padding: 13px 10px;
  resize: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--green-inactive);
  transition: all 0.4s ease-in-out;
}

.input-field__textarea:focus {
  border-color: var(--green-main);
}

.input-field__textarea:focus + .input-field__label {
  top: -0.6rem;
  left: 0.5rem;
  z-index: 1;
  font-size: 15px;
  font-weight: 500;
}

.input-field__textarea:not(:placeholder-shown).input-field__textarea:not(:focus) + .input-field__label {
  top: -0.6rem;
  left: 0.5rem;
  z-index: 1;
  font-size: var(--desktop-label-font-size-focused);
  font-weight: 500;
}

.input-field__textarea.error {
  border-color: var(--red);
}

@media screen and (max-width: 414px) {
  .input-field__textarea {
    max-height: 100px;
  }
}

@media screen and (max-width: 992px) {
  .input-field__label {
    top: 0.8rem;
    font-size: var(--mobile-label-font-size);
  }

  .input-field__input:focus + .input-field__label {
    top: -0.7rem;
    left: 0.4rem;
    z-index: 1;
    font-size: var(--mobile-label-font-size-focused);
    font-weight: 500;
  }

  .input-field__textarea:not(:placeholder-shown).input-field__textarea:not(:focus) + .input-field__label {
    top: -0.7rem;
    left: 0.4rem;
    z-index: 1;
    font-weight: 500;
  }

  .input-field__textarea:focus + .input-field__label {
    top: -0.7rem;
    left: 0.4rem;
    z-index: 1;
    font-size: var(--mobile-label-font-size-focused);
    font-weight: 500;
  }

  .input-field__textarea:not(:placeholder-shown).input-field__textarea:not(:focus) + .input-field__label {
    top: -0.7rem;
    left: 0.4rem;
    z-index: 1;
    font-weight: 500;
  }
}