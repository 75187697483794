@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
:root {
  scrollbar-width: none; /*Mozila scrollbar*/
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */ /* Konqueror */ /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgb(31, 31, 31);
}

::-webkit-scrollbar-thumb {
  background-color: #67c21e;
  border-radius: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 200;
  src: url('/src/assets/fonts/main_font/helvetica-neue-ultra-light.woff2');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: italic;
  font-weight: 300;
  src: url('/src/assets/fonts/main_font/helvetica-neue-light.woff2');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  src: url('/src/assets/fonts/main_font/helvetica-neue-normal.woff2');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  src: url('/src/assets/fonts/main_font/helvetica-neue-medium.woff2');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  src: url('/src/assets/fonts/main_font/helvetica-neue-bold.woff2');
} */

@font-face {
  font-family: 'gyre heros';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/texgyreheroscn-regular.5195ecac.otf);
}

@font-face {
  font-family: 'gyre heros';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/texgyreheroscn-bold.cd59dbb4.otf);
}

@font-face {
  font-family: 'gyre heros';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/texgyreheroscn-italic.80c67b2f.otf);
}

@font-face {
  font-family: 'gyre heros';
  font-style: italic;
  font-weight: 700;
  src: url(/static/media/texgyreheroscn-bolditalic.002aaa89.otf);
}

:root {
  --green-main: #67c21e;
  --green-hover: #2e6700;
  --green-inactive: #395c1d;
  --green-active: #d5fdb5;
  --green-hover-menu: #223611;
  --gray-main: rgba(255, 255, 255, 0.2);
  --gray-hover: rgba(255, 255, 255, 0.4);
  --white: white;
  --modal-background: #090e05;
  --red: rgb(255, 0, 0);
  --cs-background: #000d30;
  --cs-title-color: #358eff;
  --cs-challenges-title: #41e8ff;
  --saikt-main-color: #dde9ff;
  --saikt-text-color: #434242;
  --ark-main-color: #51ceec;
  --pdq-main-color: #14bc6f;
  --green-main-opacity: rgb(103, 194, 30, 0.4);
}

/* reset some default styles */
*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
strong {
  font-weight: bold;
}
input,
textarea {
  outline: none;
  font: inherit;
  border: 0;
  background: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  padding: 0;
  border: 0;
  background: 0;
  font: inherit;
}
button:focus {
  outline: 0;
}
picture {
  display: block;
}
img {
  display: block;
  width: 100%;
  height: auto;
}
svg {
  display: block;
}
pre {
  white-space: pre-wrap;
}

:root {
  --desktop-label-font-size: 24px;
  --desktop-label-font-size-focused: 15px;
  --mobile-label-font-size: 18px;
  --mobile-label-font-size-focused: 13px;
}

.input-field__label {
  color: var(--green-inactive);
  position: absolute;
  font-size: 24px;
  font-size: var(--desktop-label-font-size);
  top: 0.8rem;
  left: 0.2rem;
  background-color: var(--modal-background);
  padding: 0 8px;
  transition: 0.3s;
  pointer-events: none
}

.input-field__input {
  color: var(--white);
  width: 100%;
  padding: 13px 10px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--green-inactive);
  transition: all 0.4s ease-in-out;
}

.input-field__input:-webkit-autofill {
  -webkit-transition: background-color 1s ease-in-out 5000s, color 1s ease-in-out 5000s;
  transition: background-color 1s ease-in-out 5000s, color 1s ease-in-out 5000s;
}

.input-field__input:focus {
  border-color: var(--green-main);
}

.input-field__input:focus + .input-field__label {
  top: -0.6rem;
  left: 0.3rem;
  z-index: 1;
  font-size: 15px;
  font-size: var(--desktop-label-font-size-focused);
  font-weight: 500;
}

.input-field__input:not(:-ms-input-placeholder).input-field__input:not(:focus) + .input-field__label {
  top: -0.6rem;
  left: 0.3rem;
  z-index: 1;
  font-size: 15px;
  font-size: var(--desktop-label-font-size-focused);
  font-weight: 500;
}

.input-field__input:not(:placeholder-shown).input-field__input:not(:focus) + .input-field__label {
  top: -0.6rem;
  left: 0.3rem;
  z-index: 1;
  font-size: 15px;
  font-size: var(--desktop-label-font-size-focused);
  font-weight: 500;
}

.input-field__input.error {
  border-color: var(--red);
}

.input-field__textarea {
  font-family: 'Helvetica Neue', sans-serif;
  color: var(--white);
  width: 100%;
  min-height: 100px;
  max-height: 220px;
  height: auto;
  padding: 13px 10px;
  resize: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--green-inactive);
  transition: all 0.4s ease-in-out;
}

.input-field__textarea:focus {
  border-color: var(--green-main);
}

.input-field__textarea:focus + .input-field__label {
  top: -0.6rem;
  left: 0.5rem;
  z-index: 1;
  font-size: 15px;
  font-weight: 500;
}

.input-field__textarea:not(:-ms-input-placeholder).input-field__textarea:not(:focus) + .input-field__label {
  top: -0.6rem;
  left: 0.5rem;
  z-index: 1;
  font-size: 15px;
  font-size: var(--desktop-label-font-size-focused);
  font-weight: 500;
}

.input-field__textarea:not(:placeholder-shown).input-field__textarea:not(:focus) + .input-field__label {
  top: -0.6rem;
  left: 0.5rem;
  z-index: 1;
  font-size: 15px;
  font-size: var(--desktop-label-font-size-focused);
  font-weight: 500;
}

.input-field__textarea.error {
  border-color: var(--red);
}

@media screen and (max-width: 414px) {
  .input-field__textarea {
    max-height: 100px;
  }
}

@media screen and (max-width: 992px) {
  .input-field__label {
    top: 0.8rem;
    font-size: 18px;
    font-size: var(--mobile-label-font-size);
  }

  .input-field__input:focus + .input-field__label {
    top: -0.7rem;
    left: 0.4rem;
    z-index: 1;
    font-size: 13px;
    font-size: var(--mobile-label-font-size-focused);
    font-weight: 500;
  }

  .input-field__textarea:not(:-ms-input-placeholder).input-field__textarea:not(:focus) + .input-field__label {
    top: -0.7rem;
    left: 0.4rem;
    z-index: 1;
    font-weight: 500;
  }

  .input-field__textarea:not(:placeholder-shown).input-field__textarea:not(:focus) + .input-field__label {
    top: -0.7rem;
    left: 0.4rem;
    z-index: 1;
    font-weight: 500;
  }

  .input-field__textarea:focus + .input-field__label {
    top: -0.7rem;
    left: 0.4rem;
    z-index: 1;
    font-size: 13px;
    font-size: var(--mobile-label-font-size-focused);
    font-weight: 500;
  }

  .input-field__textarea:not(:-ms-input-placeholder).input-field__textarea:not(:focus) + .input-field__label {
    top: -0.7rem;
    left: 0.4rem;
    z-index: 1;
    font-weight: 500;
  }

  .input-field__textarea:not(:placeholder-shown).input-field__textarea:not(:focus) + .input-field__label {
    top: -0.7rem;
    left: 0.4rem;
    z-index: 1;
    font-weight: 500;
  }
}
